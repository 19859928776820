import moment from 'moment'

import { TSEnergyStarSubmissions } from '../../../../../ducks/energyStarIntegration/types'

function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.slice(1)
}
const tableDataMapper = (items: TSEnergyStarSubmissions[]) =>
  items.map((item) => {
    const tempItem = { ...item }
    tempItem.submissionDate =
      moment(tempItem.submissionDate?.split('T'), 'YYYY-MM-DD').format(
        'MM/DD/YYYY',
      ) ?? '-'
    if (tempItem.dataAggregation) {
      let { dataAggregation } = tempItem

      if (tempItem.dataAggregation !== 'wholeSite') {
        if (
          tempItem.resourceType === 'electricity' ||
          tempItem.resourceType === 'water'
        ) {
          dataAggregation = `${capitalizeFirstLetter(dataAggregation)}-${
            tempItem.meterName
          }`
        }
      } else {
        dataAggregation = 'Whole Site'
      }

      return { ...tempItem, dataAggregation }
    }

    return tempItem
  })

export default tableDataMapper
