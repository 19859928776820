// @flow

import { combineReducers } from 'redux'

import billingThisMonthItems from '../ducks/billing/billingThisMonth/billingItems'
import billingThisMonthComments from '../ducks/billing/billingThisMonth/comments'
import buildingEquipments from '../ducks/buildingEquiment'
import buildingSystems from '../ducks/buildingSystems'
import circuits from '../ducks/circuits'
import circuitsData from '../ducks/circuitsData'
import customers from '../ducks/customers'
import meterElectronVerificationHistory from '../ducks/electronMeters'
import meterElectronVerification from '../ducks/electronVerification'
import equipment from '../ducks/equipment'
import meterInstallSubmissionDetails from '../ducks/meterInstallSubmissions/meterInstallSubmissionDetails'
import meterInstallSubmissions from '../ducks/meterInstallSubmissions/meterInstallSubmissions'
import meters from '../ducks/meters'
import panelCircuits from '../ducks/panelCircuits'
import panelMetaData from '../ducks/panelPhotos'
import panels from '../ducks/panels'
import phaseGroups from '../ducks/phaseGroups'
import proposalCustomerGlobalInputs from '../ducks/proposals/customerGlobalInputs'
import proposalCustomerMetrics from '../ducks/proposals/customerMetrics'
import proposalCustomerSummaries from '../ducks/proposals/customerSummaries'
import emissionRate from '../ducks/proposals/emissionRate'
import importedScenario from '../ducks/proposals/importedScenario'
import proposalBatches from '../ducks/proposals/multisite/batches'
import proposalBatchSummaries from '../ducks/proposals/multisite/batchSummaries'
import proposalOpportunities from '../ducks/proposals/multisite/opportunities'
import proposalOpportunitySummaries from '../ducks/proposals/opportunitySummaries'
import recData from '../ducks/proposals/recData'
import proposalScenarios from '../ducks/proposals/scenarios'
import proposalSites from '../ducks/proposals/sites'
import utilityInflationRateData from '../ducks/proposals/utilityInflationRateData'
import sites from '../ducks/sites'
import specSheets from '../ducks/specSheets'
import unassignedMeters from '../ducks/unassignedMeters'
import users from '../ducks/users'
import userSummaries from '../ducks/userSummaries'
import utilityCustomerSites from '../ducks/utilityData/utilityCustomerSites'

export default combineReducers({
  billingThisMonthItems,
  billingThisMonthComments,
  buildingSystems,
  circuits,
  circuitsData,
  customers,
  equipment,
  meters,
  meterInstallSubmissionDetails,
  meterInstallSubmissions,
  panels,
  panelCircuits,
  phaseGroups,
  proposalBatchSummaries,
  proposalBatches,
  proposalCustomerGlobalInputs,
  proposalCustomerMetrics,
  proposalCustomerSummaries,
  proposalOpportunitySummaries,
  proposalOpportunities,
  proposalScenarios,
  proposalSites,
  sites,
  specSheets,
  unassignedMeters,
  users,
  userSummaries,
  utilityCustomerSites,
  importedScenario,
  emissionRate,
  recData,
  panelMetaData,
  buildingEquipments,
  utilityInflationRateData,
  meterElectronVerification,
  meterElectronVerificationHistory,
})
