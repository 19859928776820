import axios from 'axios'
import { normalize, schema } from 'normalizr'
import numeral from 'numeral'
import { combineReducers } from 'redux'
import { call, put, takeEvery } from 'redux-saga/effects'

import '../types'
import type { FTBuildingSystemSummary } from './buildingSystems'
import type { FTEquipmentSummary } from './equipment'
import type { FTMeterSummary } from './meters'
import type { FTPanelSummary } from './panels'
import type { FTPhaseGroupSummary } from './phaseGroups'
import type { FTFetchEntityList } from './types'
import {
  addMetaToResponse,
  initialMeta,
  makeActionTypes,
  makeApiQueryString,
  makeSagaPayload,
} from './utils'
import { consoleApiUrl, defaultHeaders } from '../api'
import { handleAxiosError } from '../api/utils'
import './phaseGroups'
import circuitsMockData from '../mockData/orion/circuitsMockData'
import { handleSagaError } from '../sagas/utils'
import type { FTContractSummary, FTSortable } from '../types'
import { isVariantActive, makeAlphanumericRange } from '../utils'

export const flippedCTStatusLabels = {
  FLIPPED_CT: 'Reversed',
  NEEDS_REVIEW: 'Needs Review (Standard)',
  NOT_FLIPPED_CT: 'Standard',
  NOT_LABELED: 'Insuff. Data (Standard)',
}
export type FTFlippedCtStatus =
  | 'FLIPPED_CT'
  | 'NEEDS_REVIEW'
  | 'NOT_FLIPPED_CT'
  | 'NOT_LABELED'
type FTCircuitResponse = {
  billable: boolean
  breakerNumber: string
  buildingArea: string
  buildingSystemSummary?: FTBuildingSystemSummary
  contractSummary?: FTContractSummary
  ctPort: string
  description: string
  display: string
  equipmentSummary?: FTEquipmentSummary
  externalId: string
  flippedCT?: {
    status: FTFlippedCtStatus
    defaultStatus: FTFlippedCtStatus
    manuallySet: boolean
  }
  functionalGroup: string
  id: string
  include: string
  inUse: boolean
  meterChannel: string
  meterSummary?: FTMeterSummary
  meteredUpstream: string
  name: string
  number: string
  panelSummary: FTPanelSummary
  phaseGroupSummary: FTPhaseGroupSummary
  source: string
}
export type FTCircuit = {
  buildingSystemId: string
  contractId: string
  edited: boolean
  equipmentId: string
  flippedCTLabel: string
  flippedCTManuallySet: boolean
  flippedCTStatusCurrent: FTFlippedCtStatus
  flippedCTStatusDefault: FTFlippedCtStatus
  isBigBang: boolean
  panelId: string
  pendingCTTypeAmps?: string
  phase: string
  phaseGroupId?: string
  panelFeedId?: string
  panelFeedName?: string
} & FTCircuitResponse
export type FTCircuitsById = Record<string, FTCircuit>
type FTCircuitListFetchProps = {
  source: string
  meterId: string
}
export type FTState = {
  entities: {
    circuits: {
      byId: Record<string, any>
      allIds: Array<Record<string, any>>
      meta: Record<string, any>
    }
  }
}
export type FTUpdateCircuit = {
  id: string
  buildingSystemId: string
}
export type FTMeasurementTypeMeta = {
  label: string
  unit: string
}
export type FTMeasurementTypeMap = Record<string, FTMeasurementTypeMeta>
export const measurementTypesBigBangMap: FTMeasurementTypeMap = {
  power: {
    label: 'Power',
    unit: 'kW',
  },
}
export const measurementTypesNebulaMap: FTMeasurementTypeMap = {
  rmsCurrent: {
    label: 'RMS Current',
    unit: 'A',
  },
  rmsVoltage: {
    label: 'RMS Voltage',
    unit: 'V',
  },
  phaseAngle: {
    label: 'Phase Angle',
    unit: 'Degrees',
  },
  powerFactor: {
    label: 'Power Factor',
    unit: '',
  },
  activePower: {
    label: 'Active Power',
    unit: 'kW',
  },
  fundamentalPower: {
    label: 'Fundamental Power',
    unit: 'kW',
  },
  reactivePower: {
    label: 'Reactive Power',
    unit: 'kVAR',
  },
  apparentPower: {
    label: 'Apparent Power',
    unit: 'kVA',
  },
  rmsApparentPower: {
    label: 'RMS Apparent Power',
    unit: 'kVA',
  },
  frequency: {
    label: 'Frequency',
    unit: 'Hz',
  },
}
export const getCombinedLabelFromMeasurementType = (
  type: string,
  measurementTypesMap: FTMeasurementTypeMap,
): string => {
  const measurementTypeData = measurementTypesMap[type] || {}
  const { label = '', unit } = measurementTypeData
  return unit ? `${label} (${unit})` : label
}
// ACTION TYPES
export const types = {
  LOAD_CIRCUIT: 'LOAD_CIRCUIT',
  LOAD_CIRCUIT_SUCCESS: 'LOAD_CIRCUIT_SUCCESS',
  LOAD_CIRCUIT_ERROR: 'LOAD_CIRCUIT_ERROR',
  LOAD_ALL_CIRCUITS: 'LOAD_ALL_CIRCUITS',
  LOAD_ALL_CIRCUITS_SUCCESS: 'LOAD_ALL_CIRCUITS_SUCCESS',
  LOAD_ALL_CIRCUITS_ERROR: 'LOAD_ALL_CIRCUITS_ERROR',
  BULK_UPDATE_CIRCUITS: 'BULK_UPDATE_CIRCUITS',
  BULK_UPDATE_CIRCUITS_SUCCESS: 'BULK_UPDATE_CIRCUITS_SUCCESS',
  BULK_UPDATE_CIRCUITS_ERROR: 'BULK_UPDATE_CIRCUITS_ERROR',
  ...makeActionTypes('FETCH_CT_TYPES'),
}
// REDUCERS
export const initialState = {
  byId: {},
  allIds: [],
  meta: { ...initialMeta, ctTypes: [] },
}
export const circuitSchema = new schema.Entity('circuits')

function circuitById(action, state) {
  return { ...state, ...action.payload.entities.circuits }
}

function circuitAllIds(action, state) {
  return [...new Set(state.concat(action.payload.result))]
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.LOAD_ALL_CIRCUITS:
    case types.LOAD_CIRCUIT:
      return {}

    case types.LOAD_ALL_CIRCUITS_SUCCESS:
    case types.LOAD_CIRCUIT_SUCCESS:
      return circuitById(action, state)

    default:
      return state
  }
}

function allIds(state = initialState.allIds, action) {
  switch (action.type) {
    case types.LOAD_ALL_CIRCUITS:
    case types.LOAD_CIRCUIT:
      return []

    case types.LOAD_ALL_CIRCUITS_SUCCESS:
    case types.LOAD_CIRCUIT_SUCCESS:
      return circuitAllIds(action, state)

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.LOAD_ALL_CIRCUITS:
    case types.LOAD_CIRCUIT:
    case types.FETCH_CT_TYPES:
      return { ...state, error: '', loading: true }

    case types.LOAD_ALL_CIRCUITS_ERROR:
    case types.LOAD_CIRCUIT_ERROR:
    case types.FETCH_CT_TYPES_ERROR:
      return { ...state, loading: false }

    case types.LOAD_ALL_CIRCUITS_SUCCESS:
    case types.LOAD_CIRCUIT_SUCCESS:
    case types.FETCH_CT_TYPES_SUCCESS:
      return { ...state, ...action.payload.meta, loading: false }

    case types.BULK_UPDATE_CIRCUITS:
      return { ...state, error: '', updateLoading: true }

    case types.BULK_UPDATE_CIRCUITS_ERROR:
      return { ...state, error: action.error, updateLoading: false }

    case types.BULK_UPDATE_CIRCUITS_SUCCESS:
      return { ...state, updateLoading: false }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  allIds,
  meta,
}) // ACTION CREATORS

export const actions = {
  loadAllCircuits: (props: {
    siteId?: string
    meterId?: string
    pageNumber?: number
    pageSize?: number
    orderBy?: FTSortable
  }) => ({
    type: types.LOAD_ALL_CIRCUITS,
    ...props,
  }),
  loadCircuit: ({ circuitId }: { circuitId: string }) => ({
    type: types.LOAD_CIRCUIT,
    circuitId,
  }),
  bulkUpdateCircuits: ({ circuits }: { circuits: Array<FTUpdateCircuit> }) => ({
    type: types.BULK_UPDATE_CIRCUITS,
    circuits,
  }),
  fetchCtTypes: () => ({
    type: types.FETCH_CT_TYPES,
  }),
}
// SELECTORS
export const getCircuits = (state: FTState): Array<FTCircuit> =>
  state.entities.circuits.allIds.map((id) => state.entities.circuits.byId[id])
export const getCircuitsById = (state: FTState): FTCircuitsById =>
  state.entities.circuits.byId
export const getCircuit = (state: FTState, circuitId: string) =>
  state.entities.circuits.byId[circuitId]
export const getCircuitListEntity = (state: FTState) => ({
  items: getCircuits(state),
  meta: state.entities.circuits.meta,
})
export const selectCtTypes = (state: FTState) =>
  state.entities.circuits.meta.ctTypes
export const getCircuitEntity = (state: FTState, id: string) => ({
  item: getCircuit(state, id),
  meta: state.entities.circuits.meta,
})
// API
export class API {
  static fetchCircuits(
    params: FTFetchEntityList,
  ): Promise<Array<FTCircuitResponse>> {
    if (isVariantActive('4095mock')) {
      return Promise.resolve(circuitsMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const { siteId, meterId } = params
    const query = makeApiQueryString(params, {
      siteId,
      meterId,
    })
    const url = `${consoleApiUrl()}/circuits?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => addMetaToResponse(params, response))
      .catch(handleAxiosError)
  }

  static fetchSingleCircuit(circuitId: string): Promise<FTCircuitResponse> {
    const url = `${consoleApiUrl()}/circuits/${circuitId}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => ({ ...data }))
      .catch(handleAxiosError)
  }

  static fetchCtTypes() {
    const url = `${consoleApiUrl()}/circuits/ct-types`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static bulkUpdateCircuits(circuits: Array<FTUpdateCircuit>) {
    const url = `${consoleApiUrl()}/circuits/bulk`
    return axios
      .post(url, circuits, {
        headers: defaultHeaders(),
      })
      .then(() => ({
        success: true,
      }))
      .catch(handleAxiosError)
  }
}
export const DEFAULT_CT_TYPE = 0
export const DEFAULT_BIG_BANG_CT_TYPE = 80
export const bigBangCTTypes = [80, 300]

const filterCTType = (
  ctType: number = DEFAULT_CT_TYPE,
  isBigBang: boolean = false,
) => {
  if (!isBigBang) {
    return ctType || DEFAULT_CT_TYPE
  }

  return bigBangCTTypes.includes(ctType) ? ctType : DEFAULT_BIG_BANG_CT_TYPE
}

export const breakerNumbers = makeAlphanumericRange(1, 200).map((i) => ({
  id: i,
  name: `${i}`,
}))
export const getRandomFlippedCtStatus = () =>
  Object.keys(flippedCTStatusLabels)[
    Math.floor(Math.random() * Object.keys(flippedCTStatusLabels).length)
  ]
export const utils = {
  filterCTType,
  formatCTType: (ctType: number = DEFAULT_CT_TYPE) =>
    ctType > DEFAULT_CT_TYPE ? `${numeral(ctType).format('0')}A` : 'Unassigned',
  getCircuitListFetchProps: ({ source, meterId }: FTCircuitListFetchProps) => {
    const field = utils.getMeterChannelFieldName(source === 'REDAPTIVE')
    return {
      meterId,
      orderBy: {
        field,
        sort: 'ASC',
      },
      pageSize: 300,
    }
  },
  getMeterChannelFieldName: (isRedaptive: boolean) =>
    isRedaptive ? 'meterChannel' : 'name',
  enhanceCircuit: (circuit: FTCircuitResponse) => {
    const {
      billable,
      buildingSystemSummary,
      contractSummary,
      equipmentSummary,
      flippedCT,
      meterChannel,
      meterSummary,
      meteredUpstream,
      name,
      panelSummary,
      phaseGroupSummary,
    } = circuit
    const {
      status: flippedCTStatusCurrent = isVariantActive('2826mock') ?
        getRandomFlippedCtStatus()
      : 'NOT_FLIPPED_CT',
      defaultStatus: flippedCTStatusDefault = isVariantActive('2826mock') ?
        getRandomFlippedCtStatus()
      : 'NOT_FLIPPED_CT',
      manuallySet: flippedCTManuallySet = isVariantActive('2826mock') ?
        Math.random() > 0.5
      : false,
    } = flippedCT || {}
    const flippedCTLabel =
      flippedCTStatusLabels[flippedCTStatusCurrent] || flippedCTStatusCurrent
    let isBigBang = false
    let channelId = name

    if (meterSummary) {
      const { name: meterName, source } = meterSummary

      if (meterName && meterChannel) {
        channelId = `${meterName}-${meterChannel}`
      }

      // isBigBang = meterSummary.model === meterModels.BIG_BANG;
      // TODO: Switch to above when RDP-5083 is done
      isBigBang = source === 'REDAPTIVE'
    }

    let buildingSystemId = null

    if (buildingSystemSummary && buildingSystemSummary.name) {
      buildingSystemId = buildingSystemSummary.id
    }

    let contractId = null

    if (contractSummary && contractSummary.id) {
      contractId = contractSummary.id
    }

    let equipmentId = null

    if (equipmentSummary && equipmentSummary.name) {
      equipmentId = equipmentSummary.id
    }

    let panelId = null

    if (panelSummary && panelSummary.name) {
      panelId = panelSummary.id
    }

    let phaseGroupId = null

    if (phaseGroupSummary && phaseGroupSummary.name) {
      phaseGroupId = phaseGroupSummary.id
    }

    const obj = {}
    Object.entries({ ...circuit }).forEach(([k, v]) => {
      if (v === '') {
        obj[k] = null
      } else {
        obj[k] = v
      }
    })
    return {
      ...obj,
      billable: billable || false,
      buildingSystemId,
      contractId,
      contractSummary: contractSummary || {},
      channelId,
      equipmentId,
      flippedCTLabel,
      flippedCTManuallySet,
      flippedCTStatusCurrent,
      flippedCTStatusDefault,
      isBigBang,
      panelId,
      pendingCTTypeAmps: undefined,
      phaseGroupId: phaseGroupId || null,
      meteredUpstream: !!meteredUpstream,
    }
  },
}

// Sagas
function* fetchCircuitsSaga(
  params: FTFetchEntityList,
): Generator<any, void, any> {
  try {
    const response = yield call(API.fetchCircuits, params)
    const payload = makeSagaPayload(
      response,
      circuitSchema,
      utils.enhanceCircuit,
    )
    yield put({
      type: types.LOAD_ALL_CIRCUITS_SUCCESS,
      payload,
    })
  } catch (error) {
    yield handleSagaError(types.LOAD_ALL_CIRCUITS_ERROR, error)
  }
}

function* fetchSingleCircuitSaga({
  circuitId,
}: {
  circuitId: string
}): Generator<any, void, any> {
  try {
    const circuit = yield call(API.fetchSingleCircuit, circuitId)
    const payload = normalize([circuit], [circuitSchema])
    yield put({
      type: types.LOAD_CIRCUIT_SUCCESS,
      payload,
    })
  } catch (error) {
    yield handleSagaError(types.LOAD_CIRCUIT_ERROR, error)
  }
}

function* fetchCtTypesSaga(): Generator<any, void, any> {
  try {
    const ctTypes = yield call(API.fetchCtTypes)
    const payload = {
      meta: {
        ctTypes,
      },
    }
    yield put({
      type: types.FETCH_CT_TYPES_SUCCESS,
      payload,
    })
  } catch (error) {
    yield handleSagaError(types.FETCH_CT_TYPES_ERROR, error)
  }
}

function* bulkUpdateCircuitsSaga({
  circuits,
}: {
  circuits: Array<FTUpdateCircuit>
}): Generator<any, void, any> {
  try {
    yield call(API.bulkUpdateCircuits, circuits)
    yield put({
      type: types.BULK_UPDATE_CIRCUITS_SUCCESS,
    })
  } catch (error) {
    yield handleSagaError(types.BULK_UPDATE_CIRCUITS_ERROR, error)
  }
}

export const sagas = [
  takeEvery(types.LOAD_ALL_CIRCUITS, fetchCircuitsSaga),
  takeEvery(types.LOAD_CIRCUIT, fetchSingleCircuitSaga),
  takeEvery(types.BULK_UPDATE_CIRCUITS, bulkUpdateCircuitsSaga),
  takeEvery(types.FETCH_CT_TYPES, fetchCtTypesSaga),
]
