import isEqual from 'lodash.isequal'
import numeral from 'numeral'
import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import ChannelsTable from './ChannelsTable'
import FormikForm from './FormikForm'
import Modals from './Modals'
import type { FTCircuitsById } from '../../ducks/circuits'
import { flippedCTStatusLabels } from '../../ducks/circuits'
import type { FTMeter } from '../../ducks/meters'
import {
  channelListToMap,
  channelMapFlatten,
  phaseLabels,
} from '../../ducks/meters/config'
import type {
  FTChannelConfig,
  FTPendingChannelConfig,
  FTState as FTMeterConfigState,
} from '../../ducks/meters/config'
import {
  fieldNameMap,
  getConditionalFields,
  nonNeutalPanels,
} from '../../ducks/meters/generation'
import type { FTConfirmModal } from '../../ducks/modal'
import { actions as modalActions } from '../../ducks/modal'
import type { FTPanel, FTPanelListEntityMetaState } from '../../ducks/panels'
import type { FTWithRouter } from '../../types'
import {
  formatNumberToDecimals,
  isValueSet,
  truncateDecimals,
} from '../../utils'
import Checkbox from '../Checkbox'
import {
  CTOrientationCellStyles,
  ReportProblemIconStyled,
} from '../CircuitTable/style'
import ErrorMessage from '../ErrorMessage'
import FieldWithPending from '../FieldWithPending'
import InlinePendingIcon from '../InlinePendingIcon'
import MetaText from '../MetaText'
import RedaptiveReactTable, {
  RedaptiveReactTableStyles,
} from '../RedaptiveReactTable'
import TabContent from '../Tabs/TabContent'
import VerticalTable from '../VerticalTable'
import { Label as VerticalTableLabelStyled } from '../VerticalTable/Base'
import type { ComponentType } from 'react'

const CheckboxCell = styled.td`
  white-space: nowrap;
`
const ConfigTimestamps = styled.div`
  ${MetaText} {
    display: inline;
    b {
      font-weight: 500;
    }
  }

  ${MetaText} + ${MetaText} {
    margin-left: 20px;
  }
`
const StyledConfigHeaderFields = styled.div`
  margin-top: 10px;

  ${VerticalTableLabelStyled} {
    min-width: 180px;
  }
  
  ${RedaptiveReactTableStyles} {
    .rt-tr-group {
      height: 46px;
    }
    .rt-td {
      display: flex;
      align-items: center;
    }
`
const StyledLinkContainer = styled.div`
  a {
    color: #337ab7;
    font-size: 14px;
  }
  justify-content: flex-end;
  text-align: right;
  display: flex;
`
const ConfigurationGridStyled: ComponentType<{
  isOrion: boolean
}> = styled.div`
  column-gap: 2%;
  display: grid;
  row-gap: 20px;
  grid-template-columns: ${({ isOrion }) =>
    isOrion ? '0.75fr 1.25fr 0.5fr' : 'repeat(3, 1fr)'};
`

const renderBigBangRowBody =
  (panelsById: Record<string, FTPanel>) => (row: FTPendingChannelConfig) => {
    const {
      breakerNumber = '--',
      ctTypeAmps,
      inUse,
      meterChannel,
      panelId = '',
      phase,
    } = row
    return (
      <>
        <td>{meterChannel}</td>
        <td>{(panelsById[panelId] && panelsById[panelId].name) || '--'}</td>
        <td>{breakerNumber}</td>
        <td>
          <FieldWithPending field={ctTypeAmps} />
        </td>
        <td>
          <FieldWithPending
            field={phase}
            renderField={(value) => phaseLabels.get(value) || ''}
          />
        </td>
        <CheckboxCell>
          <Checkbox name='inUse' value={inUse} checked={inUse} readOnly />
        </CheckboxCell>
      </>
    )
  }

const renderNebulaOrionRowBody =
  (panelsById: Record<string, FTPanel>) => (row: FTPendingChannelConfig) => {
    const {
      breakerNumber = '--',
      ctTypeAmps,
      inUse,
      flippedCTStatusCurrent,
      flippedCTLabel,
      meterChannel,
      panelId = '',
      phase,
    } = row
    const labelWarning = ['NOT_LABELED', 'NEEDS_REVIEW'].includes(
      flippedCTStatusCurrent,
    )
    return (
      <>
        <td>{meterChannel}</td>
        <td>{(panelsById[panelId] && panelsById[panelId].name) || '--'}</td>
        <td>{breakerNumber}</td>
        <td>
          <FieldWithPending field={ctTypeAmps} />
        </td>
        <td>
          <FieldWithPending
            field={phase}
            renderField={(value) => phaseLabels.get(value) || ''}
          />
        </td>
        <CheckboxCell>
          <Checkbox name='inUse' value={inUse} checked={inUse} readOnly />
        </CheckboxCell>
        <td>
          <CTOrientationCellStyles warning={labelWarning}>
            {labelWarning && <ReportProblemIconStyled />}
            {flippedCTLabel}
          </CTOrientationCellStyles>
        </td>
      </>
    )
  }

type FTProps = {
  circuitsById: FTCircuitsById
  panels: Array<Record<string, FTPanel>>
  panelsMeta: FTPanelListEntityMetaState
  meterConfig: FTMeterConfigState
  meter: FTMeter
  showConfirmModal: (arg0: FTConfirmModal) => void
} & FTWithRouter
type FTState = {
  isConfigHistoryModalOpen: boolean
}

class MeterConfiguration extends React.Component<FTProps, FTState> {
  constructor(props) {
    super(props)
    this.state = {
      isConfigHistoryModalOpen: false,
    }
  }

  componentDidMount() {
    const {
      location: { pathname },
    } = this.props

    if (pathname.endsWith('configuration/history')) {
      if (!this.state.isConfigHistoryModalOpen) {
        this.openConfigHistoryModal()
      }
    }
  }

  componentDidUpdate(prev) {
    const {
      meterConfig: {
        meta: { updateLoading: prevUpdateLoading },
      },
    } = prev
    const {
      meterConfig: {
        meta: { updateLoading, error },
      },
      handleSuccess,
      location: { pathname },
    } = this.props

    if (prevUpdateLoading && !updateLoading && !error) {
      handleSuccess()
      this.goBackFromForm()
    }

    if (pathname.endsWith('configuration/history')) {
      if (!this.state.isConfigHistoryModalOpen) {
        this.openConfigHistoryModal()
      }
    }
  }

  openConfigHistoryModal = () => {
    const { actions, meter } = this.props
    this.setState({
      isConfigHistoryModalOpen: true,
    })
    actions.showModalConfigHistory({
      meterId: meter.id,
      siteTimezone: meter.siteTimezone,
      closeModal: this.closeConfigHistoryModal,
    })
  }

  closeConfigHistoryModal = () => {
    const {
      actions,
      history,
      match: { url },
    } = this.props
    history.push(url)
    this.setState({
      isConfigHistoryModalOpen: false,
    })
    actions.hideModal()
  }

  getConfigHeaderFields = (): {
    panel?: Array<Record<string, any>>
    powerSource?: Array<Record<string, any>>
    misc?: Array<Record<string, any>>
  } => {
    const { meter } = this.props
    const { currentConfig, hybridConfig, isNebula, isOrion, powerSource } =
      meter

    if (!currentConfig) {
      return {}
    }

    const { dataResolution } = currentConfig
    const defaultLabel = '--'
    const {
      panel,
      vtapL1PhaseA = defaultLabel,
      vtapL2PhaseB = defaultLabel,
      vtapL3PhaseC = defaultLabel,
      stepdownTransformer = null,
      highLeg = null,
    } = powerSource || {}
    const {
      name: panelName,
      description: panelDescription,
      type: panelType,
      voltage: panelVoltage,
    } = panel || {}
    const { stepDownTransformer } = hybridConfig
    const panelFields = [
      {
        label: 'Panel Name',
        value: panelName || defaultLabel,
      },
      {
        label: 'Panel Description',
        value: panelDescription || defaultLabel,
      },
      {
        label: 'Panel Type',
        value: (panelType && fieldNameMap.get(panelType)) || defaultLabel,
      },
      {
        label: 'Panel Voltage',
        value: (panelVoltage && fieldNameMap.get(panelVoltage)) || defaultLabel,
      },
    ]
    const defaultVTapFields = [
      {
        label: 'Voltage Tap - L1 Phase A',
        value: `${vtapL1PhaseA} ${highLeg === 'L1' ? '(High Leg)' : ''}`,
      },
    ]
    const vTapFields =
      isNebula ?
        defaultVTapFields.concat([
          {
            label: 'Voltage Tap - L2 Phase B',
            value: `${vtapL2PhaseB} ${highLeg === 'L2' ? '(High Leg)' : ''}`,
          },
          {
            label: 'Voltage Tap - L3 Phase C',
            value: `${vtapL3PhaseC} ${highLeg === 'L3' ? '(High Leg)' : ''}`,
          },
        ])
      : defaultVTapFields
    const isNoNeutralNebulaPanel =
      nonNeutalPanels.includes(panelType) && isNebula
    const orionPowerSourcceFields = [
      {
        voltageTap: 'L1 - Phase A',
        breaker: `${vtapL1PhaseA} ${highLeg === 'L1' ? '(High Leg)' : ''}`,
        voltageScalingFactor: this.renderVoltageScalingFactorL1(),
        phaseAngleCorrectionFactor:
          this.renderPhaseAngleCorrectionFactorLField('phase1'),
      },
      {
        voltageTap: 'L1 - Phase B',
        breaker: `${vtapL2PhaseB} ${highLeg === 'L2' ? '(High Leg)' : ''}`,
        voltageScalingFactor: this.renderVoltageScalingFactorL2(),
        phaseAngleCorrectionFactor:
          this.renderPhaseAngleCorrectionFactorLField('phase2'),
      },
      {
        voltageTap: 'L1 - Phase C',
        breaker: `${vtapL3PhaseC} ${highLeg === 'L3' ? '(High Leg)' : ''}`,
        voltageScalingFactor: this.renderVoltageScalingFactorL3(),
        phaseAngleCorrectionFactor:
          this.renderPhaseAngleCorrectionFactorLField('phase3'),
      },
    ]
    const powerSourceFields =
      isOrion || isNoNeutralNebulaPanel ?
        [
          {
            label: 'Step-Down Transformer',
            renderField: this.renderPending(
              stepdownTransformer && stepdownTransformer.fullName ?
                stepdownTransformer.fullName
              : defaultLabel,
            ),
          },
        ].concat(orionPowerSourcceFields)
      : [
          {
            label: 'Step-Down Transformer',
            renderField: this.renderPending(
              stepdownTransformer && stepdownTransformer.fullName ?
                stepdownTransformer.fullName
              : defaultLabel,
            ),
          },
        ].concat(vTapFields)
    const scalingFactorFields =
      isOrion || isNebula ?
        [
          {
            label: 'Voltage Scaling Factor L1',
            renderField: this.renderVoltageScalingFactorL1,
          },
          {
            label: 'Voltage Scaling Factor L2',
            renderField: this.renderVoltageScalingFactorL2,
          },
          {
            label: 'Voltage Scaling Factor L3',
            renderField: this.renderVoltageScalingFactorL3,
          },
        ]
      : [
          {
            label: 'Voltage Scaling Factor',
            renderField: this.renderVoltageScalingFactor,
          },
        ]
    const miscFields =
      isOrion || isNoNeutralNebulaPanel ?
        [
          {
            label: 'Data Resolution',
            value: dataResolution,
          },
        ]
      : [
          {
            label: 'Data Resolution',
            value: dataResolution,
          },
          ...scalingFactorFields,
        ]
    return {
      panel: panelFields,
      powerSource: powerSourceFields,
      misc: miscFields,
    }
  }

  goBackFromForm = () => {
    const {
      history,
      match: { url },
    } = this.props
    history.push(url)
  }

  handleSubmit = (submission, formikBag) => {
    const { handleSubmit, showConfirmModal } = this.props
    const {
      setSubmitting,
      props: {
        meter: { isNebula, isOrion, meterOnline, panelType, verified },
        channelList: channelListInitial,
        phaseCorrectionAngles: phaseCorrectionAnglesInitial,
        voltageScalingFactor: voltageScalingInitial,
        voltageScalingFactors: voltageScalingFactorsInitial,
      },
    } = formikBag
    const { circuitsFlattened, ...submissionToSaga } = submission
    const {
      id: meterId,
      meta: meterMetaSubmissionToSaga,
      meterConfig: meterConfigSubmissionToSaga,
    } = submissionToSaga
    const {
      phaseCorrectionAngles: phaseCorrectionAnglesSubmission,
      voltageScalingFactor: voltageScalingSubmission,
      voltageScalingFactors: voltageScalingFactorsSubmission,
    } = meterConfigSubmissionToSaga
    const channelMapInitial = channelListToMap(channelListInitial)
    const circuitsInitial = channelMapFlatten(channelMapInitial)
    const circuitsInitialConfig = Object.values(circuitsInitial).map(
      ({
        breakerNumber,
        panelId,
        flippedCTStatusCurrent,
        ...circuitInitialNoBreaker
      }: any) => {
        if (!isNebula && !isOrion) {
          const { inUse, ...circuitInitialNoInUse } = circuitInitialNoBreaker
          return circuitInitialNoInUse
        }

        return circuitInitialNoBreaker
      },
    )
    const circuitsFlattenedConfig = Object.values(circuitsFlattened).map(
      ({
        breakerNumber,
        panelId,
        flippedCTStatusCurrent,
        ...circuitFlattenedNoMeta
      }: any) => {
        if (!isNebula && !isOrion) {
          const { inUse, ...circuitFlattenedNoInUse } = circuitFlattenedNoMeta
          return circuitFlattenedNoInUse
        }

        return circuitFlattenedNoMeta
      },
    )
    const circuitsConfigChanged = !isEqual(
      circuitsInitialConfig,
      circuitsFlattenedConfig,
    )
    const voltageScalingChanged =
      !isOrion &&
      !isNebula &&
      !isEqual(voltageScalingInitial, voltageScalingSubmission)
    const isNoNeutralNebulaPanel =
      nonNeutalPanels.includes(panelType) && isNebula
    const phaseCorrectionAnglesChanged =
      isOrion || isNoNeutralNebulaPanel ?
        (phaseCorrectionAnglesInitial &&
          phaseCorrectionAnglesSubmission &&
          Object.keys(phaseCorrectionAnglesInitial).some(
            (phase) =>
              phaseCorrectionAnglesInitial[phase] !==
              phaseCorrectionAnglesSubmission[phase],
          )) ||
        (phaseCorrectionAnglesInitial && !phaseCorrectionAnglesSubmission) ||
        (!phaseCorrectionAnglesInitial && phaseCorrectionAnglesSubmission)
      : false
    const voltageScalingFactorsChanged =
      isOrion || isNebula ?
        (voltageScalingFactorsInitial &&
          voltageScalingFactorsSubmission &&
          Object.keys(voltageScalingFactorsInitial).some(
            (phase) =>
              voltageScalingFactorsInitial[phase] !==
              voltageScalingFactorsSubmission[phase],
          )) ||
        (voltageScalingFactorsInitial && !voltageScalingFactorsSubmission) ||
        (!voltageScalingFactorsInitial && voltageScalingFactorsSubmission)
      : false
    const meterConfigUpdated =
      circuitsConfigChanged ||
      phaseCorrectionAnglesChanged ||
      voltageScalingChanged ||
      voltageScalingFactorsChanged
    const orionMeterConfigFields =
      isOrion ?
        {
          voltageScalingFactors: voltageScalingFactorsSubmission,
        }
      : {}
    const meterConfigFields =
      meterConfigUpdated ?
        {
          meterConfig: {
            ...meterConfigSubmissionToSaga,
            ...orionMeterConfigFields,
            voltageScalingFactor:
              isOrion ? '1.000' : truncateDecimals(voltageScalingSubmission, 3),
          },
        }
      : {}
    const updateParams = {
      id: meterId,
      meta: meterMetaSubmissionToSaga,
      ...meterConfigFields,
    }

    const renderBodyInnerForNebulaOrion =
      verified ?
        Modals.renderNebulaOrionEVdConfirm
      : Modals.renderNebulaOrionConfirm

    const renderBodyInnerForBigBang =
      verified ? Modals.renderEVdConfirm : Modals.renderConfirm

    const renderBody =
      isNebula || isOrion ?
        renderBodyInnerForNebulaOrion
      : renderBodyInnerForBigBang

    const submitFinalAction = () =>
      handleSubmit({
        ...updateParams,
        isNebula,
        isOrion,
        circuitsConfigChanged,
        meterOnline,
        phaseCorrectionAnglesChanged,
        voltageScalingChanged,
        voltageScalingFactorsChanged,
      })

    if (meterConfigUpdated) {
      showConfirmModal({
        onPrimaryAction: () => setSubmitting(false),
        primaryActionText: 'No',
        secondaryActionText: 'Yes',
        onSecondaryAction: submitFinalAction,
        renderBody,
        modalWidth: '470px',
      })
    } else {
      submitFinalAction()
    }
  }

  renderPending = (value) => () => <FieldWithPending field={value} />

  renderVoltageScalingFactor = () => {
    const { meter } = this.props
    const {
      hybridConfig: { voltageScalingFactor },
    } = meter
    return (
      <FieldWithPending // $FlowFixMe Address as part of RDP-5266
        field={voltageScalingFactor}
        renderField={(value) =>
          isValueSet(value) ? numeral(value).format('0.000') : ''
        }
      />
    )
  }

  renderVoltageScalingFactorLField = (name: string) => {
    const { meter } = this.props
    const {
      hybridConfig: { voltageScalingFactors },
    } = meter
    // $FlowFixMe
    const { current, pending } = voltageScalingFactors || {}
    const currentField =
      current ?
        {
          current: current[name],
        }
      : {}
    const pendingField =
      pending ?
        {
          pending: pending[name],
        }
      : {}
    const field = { ...currentField, ...pendingField }
    return (
      <FieldWithPending // $FlowFixMe Address as part of RDP-5266
        field={field}
        renderField={(value) =>
          isValueSet(value) ? numeral(value).format('0.0000') : '--'
        }
      />
    )
  }

  renderPhaseAngleCorrectionFactorLField = (name: string) => {
    const { meter } = this.props
    const {
      hybridConfig: { phaseCorrectionAngles },
    } = meter
    const defaultPhaseAngles = {
      phase1: -30,
      phase2: 30,
      phase3: 0,
    }
    // $FlowFixMe
    const { current, pending } = phaseCorrectionAngles || {}
    const currentField =
      current ?
        {
          current: current[name],
        }
      : {
          current: defaultPhaseAngles[name],
        }
    const pendingField =
      pending ?
        {
          pending: pending[name],
        }
      : {}
    const field = { ...currentField, ...pendingField }
    return (
      <FieldWithPending // $FlowFixMe Address as part of RDP-5266
        field={field}
        renderField={(value) =>
          isValueSet(value) ? numeral(value).format('0.00') : ''
        }
      />
    )
  }

  renderVoltageScalingFactorL1 = () =>
    this.renderVoltageScalingFactorLField('phase1')

  renderVoltageScalingFactorL2 = () =>
    this.renderVoltageScalingFactorLField('phase2')

  renderVoltageScalingFactorL3 = () =>
    this.renderVoltageScalingFactorLField('phase3')

  renderConfigTimestamps = () => {
    const {
      meter: { configLastUpdated, pendingConfigSubmitted },
      match: { url },
    } = this.props
    const configHistoryUrl = `${url}/history`
    const meta = (
      <>
        <ConfigTimestamps>
          {pendingConfigSubmitted && (
            <MetaText>
              <InlinePendingIcon /> <b>Configuration Submitted</b>
              {': '}
              {pendingConfigSubmitted}
            </MetaText>
          )}
          <MetaText>
            <b>Last Updated</b>
            {': '}
            {configLastUpdated}
          </MetaText>
        </ConfigTimestamps>
        <StyledLinkContainer>
          <Link href={configHistoryUrl} to={configHistoryUrl}>
            Configuration History
          </Link>
        </StyledLinkContainer>
      </>
    )
    return <TabContent marginTop='0px' marginBottom='0px' meta={meta} />
  }

  renderConfigHeaderFields() {
    const {
      meter: { currentConfig, isOrion, panelType, isNebula },
    } = this.props

    if (!currentConfig) {
      return null
    }

    const orionHeaderFields = [
      {
        accessor: 'voltageTap',
        Header: 'Voltage Tap',
        minWidth: 60,
      },
      {
        accessor: 'breaker',
        Header: 'Breaker',
        minWidth: 35,
      },
      {
        accessor: 'voltageScalingFactor',
        Header: 'Voltage Scaling Factor',
      },
      {
        accessor: 'phaseAngleCorrectionFactor',
        Header: 'Phase Angle Correction Factor',
      },
    ]
    const configHeaderFields = this.getConfigHeaderFields()
    const isNoNeutralNebulaPanel =
      nonNeutalPanels.includes(panelType) && isNebula
    return (
      <StyledConfigHeaderFields>
        <ConfigurationGridStyled isOrion={isOrion || isNoNeutralNebulaPanel}>
          <TabContent title='Panel' marginTop='0px' marginBottom='20px'>
            <VerticalTable.StripedFields items={configHeaderFields.panel} />
          </TabContent>
          {isOrion || isNoNeutralNebulaPanel ?
            <TabContent
              title='Power Source'
              marginTop='0px'
              marginBottom='20px'
            >
              <VerticalTable.StripedFields
                items={
                  configHeaderFields.powerSource ?
                    configHeaderFields.powerSource.slice(0, 1)
                  : []
                }
              />
              <RedaptiveReactTable
                data={
                  configHeaderFields.powerSource ?
                    configHeaderFields.powerSource.slice(1)
                  : []
                }
                columns={orionHeaderFields}
                filterable={false}
                sortable={false}
              />
            </TabContent>
          : <TabContent
              title='Power Source'
              marginTop='0px'
              marginBottom='20px'
            >
              <VerticalTable.StripedFields
                items={configHeaderFields.powerSource}
              />
            </TabContent>
          }
          <TabContent title='Misc' marginTop='0px' marginBottom='20px'>
            <VerticalTable.StripedFields items={configHeaderFields.misc} />
          </TabContent>
        </ConfigurationGridStyled>
      </StyledConfigHeaderFields>
    )
  }

  render() {
    const {
      circuitsById,
      deletePanel,
      location,
      meter,
      meterConfig,
      panels,
      panelsById,
      panelsMeta,
      showConfirmModal,
      showModalPanelForm,
      stepDownTransformers,
    } = this.props
    const { pathname } = location
    const {
      currentConfig,
      hybridConfig,
      isNebula = false,
      isOrion = false,
      generation,
      pendingConfig,
      powerSource,
    } = meter
    const {
      meta: { error, updateLoading },
    } = meterConfig

    if (!currentConfig) {
      return (
        <ErrorMessage
          message={
            'No current configuration was found for this meter.' +
            ' If you believe this is an error, please contact an Administrator.'
          }
        />
      )
    }

    const { circuitConfigurations: hybridCircuitConfigurations } = hybridConfig
    // $FlowFixMe Address as part of RDP-5266
    const {
      voltageScalingFactor,
      voltageScalingFactors,
      circuitConfigurations,
      phaseCorrectionAngles,
    } = pendingConfig || currentConfig

    const voltageScalingFactorFormatted =
      formatNumberToDecimals(voltageScalingFactor)

    const getChannelList = (configurations) =>
      configurations.map<FTChannelConfig>((circuitConfiguration) => {
        const { [circuitConfiguration.circuitId]: circuit } = circuitsById
        const {
          flippedCTLabel,
          flippedCTManuallySet,
          flippedCTStatusCurrent,
          flippedCTStatusDefault,
          panelSummary,
        } = circuit || {}
        const { id: panelId } = panelSummary || {}
        const nebulaOrionChannels =
          isNebula || isOrion ?
            {
              flippedCTLabel:
                flippedCTLabel || flippedCTStatusLabels.NOT_FLIPPED_CT,
              flippedCTManuallySet: flippedCTManuallySet || false,
              flippedCTStatusCurrent:
                flippedCTStatusCurrent || 'NOT_FLIPPED_CT',
              flippedCTStatusDefault:
                flippedCTStatusDefault || 'NOT_FLIPPED_CT',
            }
          : {}
        return {
          ...circuitConfiguration,
          breakerNumber:
            circuitsById[circuitConfiguration.circuitId] ?
              circuitsById[circuitConfiguration.circuitId].breakerNumber
            : '',
          inUse:
            circuitsById[circuitConfiguration.circuitId] &&
            circuitsById[circuitConfiguration.circuitId].inUse,
          panelId: panelId || '',
          ...nebulaOrionChannels,
        }
      })

    const channelList = getChannelList(circuitConfigurations)

    if (pathname.endsWith('/configuration/edit')) {
      return (
        <>
          {this.renderConfigTimestamps()}
          <FormikForm
            channelList={channelList}
            conditionalFields={
              generation ? getConditionalFields(generation) : {}
            }
            deletePanel={deletePanel}
            enableReinitialize
            error={error}
            goBack={this.goBackFromForm}
            meter={meter}
            panels={panels}
            panelsById={panelsById}
            panelsMeta={panelsMeta}
            powerSource={powerSource}
            showConfirmModal={showConfirmModal}
            showModalPanelForm={showModalPanelForm}
            submitAction={this.handleSubmit}
            updateLoading={updateLoading}
            voltageScalingFactor={voltageScalingFactorFormatted}
            voltageScalingFactors={voltageScalingFactors}
            phaseCorrectionAngles={phaseCorrectionAngles}
            stepDownTransformers={stepDownTransformers}
          />
        </>
      )
    }

    const renderRowBody =
      isNebula || isOrion ?
        renderNebulaOrionRowBody(panelsById)
      : renderBigBangRowBody(panelsById)
    return (
      <div>
        {this.renderConfigTimestamps()}
        {this.renderConfigHeaderFields()}
        <ChannelsTable // $FlowFixMe Address as part of RDP-5266
          renderRowBody={renderRowBody}
          items={getChannelList(hybridCircuitConfigurations)}
          isNebula={isNebula}
          isOrion={isOrion}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: { ...bindActionCreators(modalActions, dispatch) },
})

const mapStateToProps = () => ({})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MeterConfiguration),
)
