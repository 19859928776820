import axios from 'axios'
import { capitalCase } from 'capital-case'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'

import type {
  FTMeterConfig,
  FTMeterConfigUpdate,
  FTMeterConfigUpdateRequest,
} from './config'
import {
  getRenderPendingNotice,
  types as configTypes,
  utils as configUtils,
} from './config'
import type { FTMeterGeneration } from './generation'
import {
  formatGenerationAndModel,
  generations,
  getGenerationFields,
} from './generation'
import { consoleApiUrl, defaultHeaders } from '../../api'
import { handleAxiosError } from '../../api/utils'
import meterMockData from '../../mockData/orion/meterMockData'
import { handleSagaError } from '../../sagas/utils'
import type { FTSiteSource, FTSortable } from '../../types'
import { isVariantActive } from '../../utils'
import type { FTMessageInput } from '../messageFlasher'
import { actions as modalActions } from '../modal'
import type { FTPanel } from '../panels'
import '../sites'
import type { FTSiteEntityState } from '../sites'
import type { FTFilterBy } from '../types'
import type { FTEntityPayload } from '../utils'
import {
  addMetaToResponse,
  initialEntityState,
  makeActionTypes,
  makeApiQueryString,
  makeSagaPayload,
  renderTimestamp,
} from '../utils'

type FTMeterConfigCircuitConfigResponse = {
  circuitId: string
  ctType: number
  inUse: boolean
  meterChannel: string
  phase: string
}
export type FTMeterConfigVoltageScalingFactors = {
  phase1: number
  phase2: number
  phase3: number
}
export type FTMeterConfigPhaseCorrectionAngles =
  FTMeterConfigVoltageScalingFactors
export type FTMeterConfigResponse = {
  appliedTs: number | null | undefined
  circuitConfigurations: Array<FTMeterConfigCircuitConfigResponse>
  createdTs: string
  dataResolutionInSeconds: number
  id: string
  referenceFrequency: string
  version: string
  voltageScalingFactor: number
  voltageScalingFactors: FTMeterConfigVoltageScalingFactors | null | undefined
  phaseCorrectionAngles: FTMeterConfigVoltageScalingFactors | null | undefined
}
type FTMeterResponseBase = {
  active: boolean
  created: string
  customerId: string
  customerName: string
  dateMostRecentSalesforceUpdate?: string
  firstConfigDate?: string
  firstReportDate: string
  generation?: FTMeterGeneration
  hardwareId: string
  id: string
  labeledChannels: number
  lastMeasurementDate: string
  lastReportDate: string
  meterOnline: boolean
  mode: string
  model: string
  mostRecentConfigurationDate?: string
  name: string
  panelNames: Array<string>
  powerSource?: {
    groundBreaker?: string
    neutralBreaker?: string
    panel?: FTPanel
    vtapL1PhaseA?: string
    vtapL2PhaseB?: string
    vtapL3PhaseC?: string
  }
  siteId: string
  siteName: string
  siteTimezone: string
  source: string
  totalChannels: number
  verified?: boolean
  verifiedDate?: string
  deactivationReason?: string
  deactivationDate?: string
}
export type FTMeterResponse = {
  currentConfig?: FTMeterConfigResponse
  pendingConfig?: FTMeterConfigResponse
} & FTMeterResponseBase
export type FTMeter = {
  configLastUpdated?: string
  configNotice?: FTMessageInput | null | undefined
  currentConfig?: FTMeterConfig
  healthStatusCurrentMonth?: string
  healthStatusLastMonth?: string
  hybridConfig: FTMeterConfig
  isBigBang: boolean
  isNebula: boolean
  isOrion: boolean
  panelDescription?: string
  panelId?: string
  panelName?: string
  panelType?: string
  panelVoltage?: string
  pendingConfig?: FTMeterConfig
  pendingConfigSubmitted?: string
  pendingLimit: [number, string]
  verified?: boolean
  verifiedDate?: string
  verifiedDateFormatted?: string
  deactivationReason?: string
  deactivationDate?: string
  resource?: string
  display?: string
} & FTMeterResponseBase
export type FTMeterSummary = {
  description: string
  display?: string
  externalId: string
  id: string
  name: string
  source: FTSiteSource
}
export type FTMeterKFactorHistory = {
  id: string
  meterId: string
  created: string
  createdBy: string
  calculationType: string
  // UTILITY_METER, MANUAL or CALCULATED
  comment: string
  utilityMeterModel: string
  kFactor: number
  createdFormattedDate: string
}
export type FTUtilityMeter = {
  id: string
  model: string
  kFactor: number
}
export type FTMeterKFactor = {
  meterId: string
  calculationType: string
  // UTILITY_METER, MANUAL or CALCULATED
  comment: string
  utilityMeterModel: string
  kFactor: number
  fromReading: string
  toReading: string
  readingUnit: string
  fromReadingTime: string
  toReadingTime: string
  timeZone: string
}
export type FTMeterUtilityAccount = {
  id: string
  meterId: string
  utilityName: string
  utilityMeterMake: string
  utilityMeterModel: string
  utilityMeterSerialNumber: string
}
export const consts = {
  METER_TYPE_UNKNOWN: 'UNKNOWN',
}
// Entity Name
const entity = 'meters'
// Action Types
export const types = {
  ...makeActionTypes('FETCH_METER_LIST'),
  ...makeActionTypes('FETCH_METER'),
  ...makeActionTypes('UPDATE_METER'),
  ...makeActionTypes('FETCH_KFACTOR_HISTORY'),
  ...makeActionTypes('UPDATE_UTILITY_ACCOUNT'),
  ...makeActionTypes('FETCH_UTILITY_METER_MODELS'),
  ...makeActionTypes('ADD_KFACTOR'),
  ...makeActionTypes('CALCULATE_KFACTOR'),
  ...makeActionTypes('ADD_CALCULATED_KFACTOR'),
  ...makeActionTypes('FETCH_STEP_DOWN_TRANSFORMERS'),
  RESET_MEASUREMENT_META: 'RESET_MEASUREMENT_META',
}

const getConfigTimestamps = (meter: FTMeterResponse) => {
  const { siteTimezone, currentConfig, pendingConfig } = meter
  const noCurrentTimestamp = !currentConfig || !currentConfig.appliedTs
  const { appliedTs } = currentConfig || {}
  const configLastUpdated =
    noCurrentTimestamp ? 'Never' : (
      renderTimestamp(appliedTs, siteTimezone, 'YYYY-MM-DD h:mm A z')
    )
  const { createdTs } = pendingConfig || {}
  const pendingConfigSubmitted =
    createdTs ?
      renderTimestamp(createdTs, siteTimezone, 'YYYY-MM-DD h:mm A z')
    : undefined
  return {
    configLastUpdated,
    pendingConfigSubmitted,
  }
}

// Utils
export const utils = {
  sourceToMeterType: (source: string, empty: string = '') =>
    capitalCase(source) || empty,
  getLabeledTotal: (labeled: number, total: number) =>
    `${labeled || 0} / ${total || 0} total`,
  getMeterStatus: (meterOnline: boolean) =>
    meterOnline ? 'Online' : 'Offline',

  /**
   * Gets the value to display for the "Last Reported" date.
   *
   * For Enertiv meters, the lastReportDate field is never populated, so the
   * lastMeasurementDate is used instead.
   * See https://redaptiveinc.atlassian.net/browse/RDP-5633.
   *
   * @return {string} The rendered timestamp for display.
   */
  getLastReported: ({
    source,
    lastReportDate,
    lastMeasurementDate,
    siteTimezone,
  }: FTMeterResponse) =>
    source === 'ENERTIV' ?
      renderTimestamp(lastMeasurementDate, siteTimezone)
    : renderTimestamp(lastReportDate, siteTimezone),
  enhanceMeters: (meter: FTMeterResponse) => {
    const {
      dateMostRecentSalesforceUpdate,
      firstConfigDate,
      generation: generationResponse = generations.UNKNOWN,
      lastReportDate,
      model,
      mostRecentConfigurationDate,
      powerSource,
      siteTimezone,
      verifiedDate,
    } = meter
    const { panel } = powerSource || {}
    const isOrion = model.startsWith('RPM-C-4806')
    const generation = isOrion ? 'OR' : generationResponse
    const isBigBang = generation === generations.BIG_BANG
    const isNebula = generation === generations.NEBULA
    const { generation: generationName, model: modelName } =
      formatGenerationAndModel({
        generation,
        model,
      })
    const {
      validCtTypes,
      validScalingFactors,
      validScalingFactorsById,
      pendingLimit,
    } = getGenerationFields(generation)
    const pendingConfig = configUtils.enhanceMeterConfig(
      meter.pendingConfig,
      validCtTypes,
      validScalingFactorsById,
    )
    const currentConfig = configUtils.enhanceMeterConfig(
      meter.currentConfig,
      validCtTypes,
      validScalingFactorsById,
    )
    // $FlowFixMe Address as part of RDP-5266
    const hybridConfig = configUtils.makeHybridConfig(
      pendingConfig,
      currentConfig,
    )
    const { configLastUpdated, pendingConfigSubmitted } =
      getConfigTimestamps(meter)
    const configNotice = configUtils.getMeterConfigNotice({
      meter,
      pendingLimit,
    })
    const {
      id: panelId,
      name: panelName = '',
      description: panelDescription = '',
      type: panelType = null,
      voltage: panelVoltage = null,
    } = panel || {}
    return {
      ...meter,
      configLastUpdated,
      configNotice,
      currentConfig,
      dateMostRecentSalesforceUpdate: renderTimestamp(
        dateMostRecentSalesforceUpdate,
        siteTimezone,
      ),
      firstConfigDate: renderTimestamp(firstConfigDate, siteTimezone),
      generation,
      generationName,
      hybridConfig,
      isBigBang,
      isNebula,
      isOrion,
      labeledTotal: utils.getLabeledTotal(
        meter.labeledChannels,
        meter.totalChannels,
      ),
      lastReportDate: renderTimestamp(lastReportDate, siteTimezone),
      lastReported: utils.getLastReported(meter),
      mostRecentConfigurationDate: renderTimestamp(
        mostRecentConfigurationDate,
        siteTimezone,
      ),
      meterStatus: utils.getMeterStatus(meter.meterOnline),
      meterType: utils.sourceToMeterType(meter.source),
      model: modelName,
      panelDescription,
      panelId,
      panelName,
      panelType,
      panelVoltage,
      pendingConfig,
      pendingConfigSubmitted,
      pendingLimit,
      validCtTypes,
      validScalingFactors,
      validScalingFactorsById,
      verifiedDateFormatted:
        verifiedDate ? renderTimestamp(verifiedDate, siteTimezone) : '',
    }
  },
}
type FTMeterEntityMetaState = {
  pageNumber: number | null | undefined
  pageSize: number | null | undefined
  next: string | null | undefined
  previous: string | null | undefined
  error: string | null | undefined
  loading: boolean
}
type FTMeterEntityState = {
  byId: Record<string, any>
  allIds: Array<string>
  meta: FTMeterEntityMetaState
}
export type FTState = {
  entities: {
    meters: FTMeterEntityState
    sites: FTSiteEntityState
  }
}

type FTStepDownTransformer = {
  id: string
  name: string
  type: string
}

// Reducers
export const entitySchema = new schema.Entity(entity)

function entityById(action, state) {
  return { ...state, ...action.payload.entities[entity] }
}

function entityAllIds(action, state) {
  return [...new Set(state.concat(action.payload.result))]
}

/**
 * Filters fields from a meter in state that are not included in a payload.
 *
 * @param {Object} payloadMeter - The meter in the payload.
 * @param {Object} stateMeter - The meter in the state.
 */
const getFilteredMeterStateFromPayload = (payloadMeter, stateMeter) => {
  const unsettableFields = ['verifiedDate']
  const meterKeys = Object.keys(payloadMeter)
  return Object.keys(stateMeter).reduce(
    (acc, cur) =>
      unsettableFields.includes(cur) && !meterKeys.includes(cur) ?
        acc
      : { ...acc, [cur]: stateMeter[cur] },
    {},
  )
}

function updateMeterStateById(action, state) {
  const newState = { ...state }
  Object.keys(action.payload.entities[entity]).forEach((id) => {
    const payloadMeter = action.payload.entities[entity][id]
    const updatedPayloadMeter = {
      opportunityId: '',
      ...payloadMeter,
    }
    // Combines the new meter state with the old meter state, if it exists.
    const newMeterStateRaw =
      state[id] ?
        {
          ...getFilteredMeterStateFromPayload(payloadMeter, state[id]),
          ...updatedPayloadMeter,
        }
      : updatedPayloadMeter
    newState[id] = utils.enhanceMeters(newMeterStateRaw)
  })
  return newState
}

function byId(state = initialEntityState.byId, action) {
  switch (action.type) {
    case types.FETCH_METER_LIST:
    case types.FETCH_METER:
      return {}

    case types.FETCH_METER_LIST_SUCCESS:
    case types.FETCH_METER_SUCCESS:
      return entityById(action, state)

    case types.UPDATE_METER_SUCCESS:
      return updateMeterStateById(action, state)

    default:
      return state
  }
}

function allIds(state = initialEntityState.allIds, action) {
  switch (action.type) {
    case types.FETCH_METER_LIST:
    case types.FETCH_METER:
      return []

    case types.FETCH_METER_LIST_SUCCESS:
    case types.FETCH_METER_SUCCESS:
    case types.UPDATE_METER_SUCCESS:
      return entityAllIds(action, state)

    default:
      return state
  }
}

function meta(state = initialEntityState.meta, action) {
  switch (action.type) {
    case types.FETCH_METER_LIST:
    case types.FETCH_METER:
    case types.UPDATE_METER:
    case types.UPDATE_UTILITY_ACCOUNT:
    case types.ADD_KFACTOR:
    case types.CALCULATE_KFACTOR:
    case types.ADD_CALCULATED_KFACTOR:
      return { ...state, loading: true, error: null }

    case types.FETCH_METER_LIST_ERROR:
    case types.FETCH_METER_ERROR:
    case types.UPDATE_METER_ERROR:
    case types.UPDATE_UTILITY_ACCOUNT_ERROR:
    case types.ADD_KFACTOR_ERROR:
    case types.ADD_CALCULATED_KFACTOR_ERROR:
    case types.CALCULATE_KFACTOR_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_METER_LIST_SUCCESS:
    case types.FETCH_METER_SUCCESS:
    case types.UPDATE_UTILITY_ACCOUNT_SUCCESS:
    case types.ADD_KFACTOR_SUCCESS:
    case types.ADD_CALCULATED_KFACTOR_SUCCESS:
      return { ...state, ...action.payload.meta, loading: false }

    case types.UPDATE_METER_SUCCESS:
    case types.CALCULATE_KFACTOR_SUCCESS:
      return { ...state, loading: false, error: null }

    case types.FETCH_KFACTOR_HISTORY:
    case types.FETCH_KFACTOR_HISTORY_SUCCESS:
      return { ...state, loading: false }

    case types.FETCH_KFACTOR_HISTORY_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_UTILITY_METER_MODELS:
    case types.FETCH_UTILITY_METER_MODELS_SUCCESS:
      return { ...state, loading: false }

    case types.FETCH_UTILITY_METER_MODELS_ERROR:
      return { ...state, error: action.error, loading: false }

    default:
      return state
  }
}

function kFactorHistory(state = initialEntityState.kFactorHistory, action) {
  switch (action.type) {
    case types.FETCH_KFACTOR_HISTORY:
      return { ...state, items: action.payload }

    case types.FETCH_KFACTOR_HISTORY_SUCCESS:
      return { ...state, items: action.payload }

    case types.CALCULATE_KFACTOR_SUCCESS:
      return { ...state, measurementMeta: action.payload }

    case types.RESET_MEASUREMENT_META:
      return { ...state, measurementMeta: {} }

    case types.ADD_KFACTOR_SUCCESS:
    case types.ADD_CALCULATED_KFACTOR_SUCCESS:
      return {
        ...state,
        items: [action.payload, ...state.items],
        measurementMeta: {},
        loading: false,
      }

    default:
      return state
  }
}

function stepDownTransformers(
  state = initialEntityState.stepDownTransformers,
  action,
) {
  switch (action.type) {
    case types.FETCH_STEP_DOWN_TRANSFORMERS:
      return { ...state, items: [] }

    case types.FETCH_STEP_DOWN_TRANSFORMERS_SUCCESS:
      return { ...state, items: action.payload.results }

    default:
      return state
  }
}

function utilityMeterModels(
  state = initialEntityState.utilityMeterModels,
  action,
) {
  switch (action.type) {
    case types.FETCH_UTILITY_METER_MODELS:
      return { ...state, items: action.payload }

    case types.FETCH_UTILITY_METER_MODELS_SUCCESS:
      return { ...state, items: action.payload }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  allIds,
  meta,
  kFactorHistory,
  stepDownTransformers,
  utilityMeterModels,
}) // Action Creators

export const actions = {
  fetchMeterList: (params: {
    customerId?: string
    siteId?: string
    pageNumber?: number
    pageSize?: number
    orderBy?: FTSortable
    filterBy?: FTFilterBy
  }) => ({
    type: types.FETCH_METER_LIST,
    ...params,
  }),
  fetchMeter: (props: { id: string }) => ({
    type: types.FETCH_METER,
    ...props,
  }),
  updateMeter: (params: { meter: any }) => ({
    type: types.UPDATE_METER,
    ...params,
  }),
  updateMeterConfig: (params: FTMeterConfigUpdate) => ({
    type: configTypes.UPDATE_METER_CONFIG,
    ...params,
  }),
  fetchKFactorHistory: (params: { id: string }) => ({
    type: types.FETCH_KFACTOR_HISTORY,
    ...params,
  }),
  updateUtilityAccount: (params: FTMeterUtilityAccount) => ({
    type: types.UPDATE_UTILITY_ACCOUNT,
    ...params,
  }),
  fetchUtilityMeterModels: () => ({
    type: types.FETCH_UTILITY_METER_MODELS,
  }),
  calculateMeasurements: (params: {
    meterId: string
    data: Record<string, any>
  }) => ({
    type: types.CALCULATE_KFACTOR,
    ...params,
  }),
  addKFactor: (payload: FTMeterKFactor) => ({
    type: types.ADD_KFACTOR,
    ...payload,
  }),
  fetchStepDownTransformers: () => ({
    type: types.FETCH_STEP_DOWN_TRANSFORMERS,
  }),
  addCalculatedKFactor: (payload: {
    hardwareId: string
    meterId: string
    body: Record<string, any>
  }) => ({
    type: types.ADD_CALCULATED_KFACTOR,
    ...payload,
  }),
  resetMeasurementMeta: () => ({
    type: types.RESET_MEASUREMENT_META,
  }),
}
// SELECTORS
export const selectMeterList = (state: FTState): Array<FTMeter> =>
  state.entities[entity].allIds.map((id) => state.entities[entity].byId[id])
export const selectMeterListWithHealth = (state: FTState): Array<FTMeter> => {
  const {
    entities: {
      sites: {
        siteHealth: {
          healthStatusPerMeterLastMonth,
          healthStatusPerMeterCurrentMonth,
        },
      },
    },
  } = state
  return state.entities[entity].allIds.map((meterId) => {
    const meter = state.entities[entity].byId[meterId]
    const healthStatusLastMonth = healthStatusPerMeterLastMonth[meterId]
    const healthStatusCurrentMonth = healthStatusPerMeterCurrentMonth[meterId]
    return { ...meter, healthStatusLastMonth, healthStatusCurrentMonth }
  })
}
export const selectMeter = (state: FTState, id: string) =>
  state.entities[entity].byId[id]
export const selectMeterListEntity = (
  state: FTState,
): {
  items: Array<FTMeter>
  meta: FTMeterEntityMetaState
} => ({
  items: selectMeterList(state),
  meta: state.entities[entity].meta,
})
export const selectMeterListWithHealthEntity = (state: FTState) => ({
  items: selectMeterListWithHealth(state),
  meta: state.entities[entity].meta,
})
export const selectMeterEntity = (state: FTState, id: string) => ({
  item: selectMeter(state, id),
  meta: state.entities[entity].meta,
})
export const selectKFactorHistoryItems = (state: FTEntityPayload) =>
  state.entities[entity].kFactorHistory.items
export const selectMeasurementMeta = (state: FTEntityPayload) =>
  state.entities[entity].kFactorHistory.measurementMeta
export const selectUtilityMeterModelsItems = (state: FTEntityPayload) =>
  state.entities[entity].utilityMeterModels.items
export const selectStepDownTransformersItems = (state: FTEntityPayload) =>
  state.entities[entity].stepDownTransformers.items

// API
export class API {
  static updateMeters(meterList: Array<any>) {
    const url = `${consoleApiUrl()}/meters`
    return axios
      .patch(url, meterList, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static updateMeterConfig(params: FTMeterConfigUpdateRequest) {
    const { type: _, id, ...meterConfig } = params
    const url = `${consoleApiUrl()}/meters/${id}/config`
    return axios
      .put(url, meterConfig, {
        headers: defaultHeaders(),
      })
      .then(() => ({}))
      .catch(handleAxiosError)
  }

  static fetchMeterList(params: Record<string, any>) {
    const { customerId, siteId } = params
    const query = makeApiQueryString(params, {
      customerId,
      siteId,
    })
    const url = `${consoleApiUrl()}/meters/list?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => addMetaToResponse(params, response))
      .catch(handleAxiosError)
  }

  static fetchMeterListForEV(params: Record<string, any>) {
    const {
      customerId,
      siteId,
      meterMAC,
      opportunityId,
      pageSize = 20,
      pageNumber = 1,
    } = params
    let query = ''
    if (customerId && siteId) {
      query = `siteId=${siteId}`
    } else if (meterMAC) {
      query = `filterName=${meterMAC}`
    } else if (opportunityId) {
      query = `opportunityId=${opportunityId}`
    }
    if (pageSize) {
      query = `${query}&pageSize=${pageSize}`
    }
    if (pageNumber) {
      query = `${query}&pageNumber=${pageNumber}`
    }
    const url = `${consoleApiUrl()}/meters/list?${query}&generation=NEBULA`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => addMetaToResponse(params, response))
      .catch(handleAxiosError)
  }

  static fetchMeterListWithExtraDetails(params: Record<string, any>) {
    const { customerId, siteId } = params
    const query = makeApiQueryString(params, {
      customerId,
      siteId,
    })
    const url = `${consoleApiUrl()}/meters?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => addMetaToResponse(params, response))
      .catch(handleAxiosError)
  }

  static fetchMeter({ id }: { id: string }): Promise<FTMeterResponse> {
    if (isVariantActive('4095mock')) {
      return Promise.resolve(meterMockData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 200)),
      )
    }

    const url = `${consoleApiUrl()}/meters/${id}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static fetcMeterKFactorHistory({
    id,
  }: {
    id: string
  }): Promise<Array<FTMeterKFactorHistory>> {
    const url = `${consoleApiUrl()}/gasmeter/${id}/k-factor/history`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static updateUtilityAccount({ meterId, ...body }: FTMeterUtilityAccount) {
    const url = `${consoleApiUrl()}/meters/${meterId}/utility-account`
    return axios
      .post(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static fetchUtilityMeterModels(): Promise<Array<FTUtilityMeter>> {
    const url = `${consoleApiUrl()}/gasmeter/utility-meter-models`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static addKFactor({
    meterId,
    ...body
  }: FTMeterKFactor): Promise<FTMeterKFactor> {
    const url = `${consoleApiUrl()}/gasmeter/${meterId}/k-factor`
    return axios
      .patch(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static addCalculatedKFactor({
    meterId,
    body,
  }: {
    meterId: string
    body: FTMeterKFactor
  }): Promise<FTMeterKFactor> {
    const url = `${consoleApiUrl()}/gasmeter/${meterId}/k-factor`
    return axios
      .patch(url, body, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static async uploadMeterPhoto({
    hardwareId,
    photoData,
  }: {
    hardwareId: string
    photoData: Record<string, any>
  }): Promise<Record<string, any>> {
    const OBJECT_TYPE = 'gas_meter_reading'
    const url = `${consoleApiUrl()}/files/metadata`
    const filesData = []
    const fileBlobs = []
    const contentTypes = []

    if (photoData.length > 0) {
      photoData.forEach((file) => {
        const preSignedUrlRequestData = {
          id: uuidv4(),
          objectType: OBJECT_TYPE,
          objectId: hardwareId,
          fileName: file.name,
        }
        filesData.push(preSignedUrlRequestData)
        fileBlobs.push(
          new Blob([file], {
            type: file.type,
          }),
        )
        contentTypes.push(file.type)
      })
    }

    const { data } = await axios.post(url, filesData, {
      headers: defaultHeaders(),
    })

    if (data.length > 0) {
      const uploadPromises = []
      data.forEach((fileData, index) => {
        const { preSignedUrl } = fileData
        const raw = fileBlobs[index]
        const requestOptions = {
          headers: {
            'Content-Type': contentTypes[index],
          },
          method: 'PUT',
          body: raw,
          redirect: 'follow',
        }
        uploadPromises.push(fetch(preSignedUrl, requestOptions))
      })
      const uploadResponse = await Promise.all(uploadPromises)
      const updateStatusPromises = []
      uploadResponse.forEach((response, index) => {
        if (response.status !== 200) {
          throw new Error('Upload failed')
        }

        const fileId = data[index].id
        const tempPromise = axios.patch(
          `${consoleApiUrl()}/files/${fileId}/status`,
          {
            status: 'SUCCESS',
          },
          {
            headers: defaultHeaders(),
          },
        )
        updateStatusPromises.push(tempPromise)
      })
      return Promise.all(updateStatusPromises)
    }

    return 500
  }

  static calculateMeasurements({
    meterId,
    data,
  }: {
    meterId: string
    data: Record<string, any>
  }): Promise<Record<string, any>> {
    return axios.post(
      `${consoleApiUrl()}/gasmeter/${meterId}/measurements`,
      data,
      {
        headers: defaultHeaders(),
      },
    )
  }

  static fetchStepDownTransformers(): Promise<Array<FTStepDownTransformer>> {
    return axios
      .get(
        `${consoleApiUrl()}/devices?type=STEPDOWN_TRANSFORMER&pageNumber=1&pageSize=100`,
        {
          headers: defaultHeaders(),
        },
      )
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }
}

function* fetchMeterListSaga(params: {
  customerId: string
  siteId: string
  pageNumber: number | null | undefined
  pageSize: number | null | undefined
  orderBy: FTSortable | null | undefined
  useDeprecatedMeterList?: boolean
}): Generator<any, void, any> {
  try {
    const response = yield call(
      params.useDeprecatedMeterList ?
        API.fetchMeterListWithExtraDetails
      : API.fetchMeterList,
      params,
    )
    const payload =
      params.siteId ?
        makeSagaPayload(response, entitySchema, utils.enhanceMeters)
      : makeSagaPayload(response, entitySchema)
    yield put({
      type: types.FETCH_METER_LIST_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_METER_LIST_ERROR, e)
  }
}

function* fetchMeterSaga(params: { id: string }): Generator<any, void, any> {
  try {
    const response = yield call(API.fetchMeter, params)
    const payload = normalize([utils.enhanceMeters(response)], [entitySchema])
    yield put({
      type: types.FETCH_METER_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_METER_ERROR, e)
  }
}

function* updateMeter({ type, ...meter }): Generator<any, void, any> {
  try {
    const [response = {}] = yield call(API.updateMeters, [meter])
    const updatedResonse = {
      opportunityType: null,
      ...response,
    }
    const payload = normalize([updatedResonse], [entitySchema])
    yield put({
      type: types.UPDATE_METER_SUCCESS,
      payload,
    })
  } catch (error) {
    yield handleSagaError(types.UPDATE_METER_ERROR, error)
  }
}

function* updateMeterConfig(
  params: FTMeterConfigUpdate,
): Generator<any, void, any> {
  try {
    const {
      isNebula,
      isOrion,
      meterOnline,
      circuitsConfigChanged,
      phaseCorrectionAnglesChanged,
      voltageScalingChanged,
      voltageScalingFactorsChanged,
      ...updateParams
    } = params
    yield call(API.updateMeterConfig, updateParams)
    yield put({
      type: configTypes.UPDATE_METER_CONFIG_SUCCESS,
    })

    if (
      circuitsConfigChanged ||
      phaseCorrectionAnglesChanged ||
      voltageScalingChanged ||
      voltageScalingFactorsChanged
    ) {
      yield put(
        modalActions.showConfirmModal({
          primaryActionText: 'OK',
          renderBody: getRenderPendingNotice(meterOnline, isNebula, isOrion),
          modalWidth: '418px',
        }),
      )
    }
  } catch (error) {
    yield handleSagaError(configTypes.UPDATE_METER_CONFIG_ERROR, error)
  }
}

function* fetchMeterKFactorHistorySaga(params: {
  id: string
}): Generator<any, void, any> {
  try {
    const response = yield call(API.fetcMeterKFactorHistory, params)
    yield put({
      type: types.FETCH_KFACTOR_HISTORY_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_KFACTOR_HISTORY_ERROR, e)
  }
}

function* fetchUtilityMeterModels(): Generator<any, void, any> {
  try {
    const response = yield call(API.fetchUtilityMeterModels)
    yield put({
      type: types.FETCH_UTILITY_METER_MODELS_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_UTILITY_METER_MODELS_ERROR, e)
  }
}

function* fetchStepDownTransformers(): Generator<any, void, any> {
  try {
    const response = yield call(API.fetchStepDownTransformers)
    yield put({
      type: types.FETCH_STEP_DOWN_TRANSFORMERS_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_STEP_DOWN_TRANSFORMERS_ERROR, e)
  }
}

function* addKFactor({ type, ...data }) {
  try {
    const { meterId, ...body } = data
    const response = yield call(API.addKFactor, {
      meterId,
      ...body,
    })
    yield put({
      type: types.ADD_KFACTOR_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.ADD_KFACTOR_ERROR, e)
  }
}

function* updateUtilityAccountSaga({
  type,
  ...data
}): Generator<any, void, any> {
  try {
    const { meterId, ...body } = data
    yield call(API.updateUtilityAccount, {
      meterId,
      ...body,
    })
    yield put({
      type: types.UPDATE_UTILITY_ACCOUNT_SUCCESS,
    })
  } catch (error) {
    yield handleSagaError(types.UPDATE_UTILITY_ACCOUNT_ERROR, error)
  }
}

function* calculateMeasurementsSaga({
  type,
  ...data
}): Generator<any, void, any> {
  try {
    const response = yield call(API.calculateMeasurements, data)
    yield put({
      type: types.CALCULATE_KFACTOR_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    yield handleSagaError(types.CALCULATE_KFACTOR_ERROR, error)
  }
}

function* addCalculatedKFactor({ type, ...data }): Generator<any, void, any> {
  try {
    const { hardwareId, meterId, body } = data
    const photoResponse = yield call(API.uploadMeterPhoto, {
      hardwareId,
      photoData: body.photos,
    })

    if (photoResponse === 500) {
      yield put({
        type: types.ADD_CALCULATED_KFACTOR_ERROR,
        error: 'Error uploading photo',
      })
      return
    }

    const bodyWithPhotoId = {
      ...body.kFactorData,
      fromReadingPhotoId: photoResponse[0].data.id,
      toReadingPhotoId: photoResponse[1].data.id,
    }
    const response = yield call(API.addCalculatedKFactor, {
      meterId,
      body: bodyWithPhotoId,
    })
    yield put({
      type: types.ADD_KFACTOR_SUCCESS,
      payload: response,
    })
    yield put(modalActions.hideModal())
  } catch (error) {
    yield handleSagaError(types.ADD_CALCULATED_KFACTOR_ERROR, error)
  }
}

export const sagas = [
  takeLatest(types.FETCH_METER_LIST, fetchMeterListSaga),
  takeLatest(types.FETCH_METER, fetchMeterSaga),
  takeEvery(types.UPDATE_METER, updateMeter),
  takeLatest(configTypes.UPDATE_METER_CONFIG, updateMeterConfig),
  takeLatest(types.FETCH_KFACTOR_HISTORY, fetchMeterKFactorHistorySaga),
  takeLatest(types.FETCH_UTILITY_METER_MODELS, fetchUtilityMeterModels),
  takeLatest(types.FETCH_STEP_DOWN_TRANSFORMERS, fetchStepDownTransformers),
  takeLatest(types.UPDATE_UTILITY_ACCOUNT, updateUtilityAccountSaga),
  takeLatest(types.ADD_KFACTOR, addKFactor),
  takeLatest(types.CALCULATE_KFACTOR, calculateMeasurementsSaga),
  takeLatest(types.ADD_CALCULATED_KFACTOR, addCalculatedKFactor),
]
