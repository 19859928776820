import moment from 'moment'

import {
  TSEnergyStarSubmissions,
  TSExportData,
} from '../../../../../ducks/energyStarIntegration/types'
import {
  formatHealthStatus,
  getNumberFormatMaxFractionDigits2,
} from '../../../../../utils'
import {
  DataSourceValueMap,
  ResourceValueMap,
  StatusViewValueMap,
  SubmissionStages,
  TabUrlEnum,
} from '../../../helpers/utils'
import getFormattedCaseStartDate from '../../helpers/getFormattedCaseStartDate'

const exportDataMapper = (items: TSEnergyStarSubmissions[]): TSExportData => {
  const data = items.map((item) => ({
    ...item,
    resourceType: ResourceValueMap[item.resourceType] || item.resourceType,
    dataSource: DataSourceValueMap[item.dataSource] || item.dataSource,
    status:
      StatusViewValueMap[item.status as keyof typeof StatusViewValueMap] ||
      item.status,
    dataAvailability: formatHealthStatus(item.dataAvailability),
    deltaEnergyConsumption:
      item.deltaEnergyConsumption ?
        `${Math.round(item.deltaEnergyConsumption)}%`
      : 'NA',
    espmStartdate:
      item.espmStartdate ?
        moment(item.espmStartdate.split('T'), 'YYYY-MM-DD').format('MM-DD-YYYY')
      : 'NA',
    meterList: item.meterList?.flatMap((i) => i).join(', ') ?? '',
    caseId: item.caseId?.join(', '),
    caseStatus: item.caseStatus?.join(', '),
    caseStartDate: item.caseStartDate
      ?.map(getFormattedCaseStartDate)
      ?.join(', '),
    caseAssignedTo: item.caseAssignedTo?.join(', '),
    opportunityId: item.opportunityId?.join(', '),
    currentMonthEnergyConsumption:
      item.currentMonthEnergyConsumption ?
        getNumberFormatMaxFractionDigits2('en-US').format(
          item.currentMonthEnergyConsumption,
        )
      : '-',
    previousMonthEnergyConsumption:
      item.previousMonthEnergyConsumption ?
        getNumberFormatMaxFractionDigits2('en-US').format(
          item.previousMonthEnergyConsumption,
        )
      : '-',
    submissionDate:
      item.submissionDate ?
        moment(item.submissionDate.split('T'), 'YYYY-MM-DD').format(
          'MM/DD/YYYY',
        )
      : 'NA',
    modifiedBy: item.modifiedBy ?? 'NA',
  }))

  return (
    Object.keys(TabUrlEnum) as (keyof typeof TabUrlEnum)[]
  ).reduce<TSExportData>(
    (acc, key) => ({
      ...acc,
      [TabUrlEnum[key]]: data.filter(
        (item) => item.currentStage === SubmissionStages[key],
      ),
    }),
    {} as TSExportData,
  )
}

export default exportDataMapper
