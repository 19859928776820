import { Route, Switch } from 'react-router-dom'

import BillingMainPage from './BillingMainPage'
import type { FTWithRouter } from '../../types'
import '../../types'
import BillingThisMonth from './BillingThisMonth'
import ISRFileProcessorIndex from './ISRFileProcessor'

type FTProps = FTWithRouter

const Billing = (props: FTProps) => {
  const {
    match: { path },
  } = props
  return (
    <Switch>
      <Route path={`${path}/this-month`} component={BillingThisMonth} />
      <Route
        path={`${path}/isr-file-processor`}
        component={ISRFileProcessorIndex}
      />
      <Route component={BillingMainPage} />
    </Switch>
  )
}

export default Billing
