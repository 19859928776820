import debounce from 'debounce'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import type { FTFilterBy } from '../../ducks/types'
import SearchInputEvent from '../../utils/keyboard'
import Input from '../Input'

export const SearchBarStyled = styled.div`
  margin-bottom: 30px;
  width: 400px;
  margin-right: 40px;

  input {
    background-color: #ffffff;
    border-radius: 3px;
    border: 1px solid #c7c7c7;
    color: #4a4a4a;
    font: 14px 'Avenir Next';
    line-height: 19px;
    padding: 9px 11px 8px 11px;
    visibility: ${({ hidden }) => hidden && 'hidden'};
    width: 400px;
  }
`
type FTProps = {
  onSearch: (term: string) => null
  filterField: string
  previous: FTFilterBy
}

const SearchBar = (props: FTProps) => {
  const inputRef = useRef()
  const [searchUsed, setSearchUsed] = useState(false)
  useEffect(() => {
    if (searchUsed) {
      return
    }

    const { previous } = props
    const { term = '' } = previous || {}
    inputRef.current.value = term
  }, [])

  const search = (event) => {
    const {
      target: { value: term },
    } = event
    const { onSearch, filterField, previous } = props
    const previousTerm = previous ? previous.term : ''
    const keyboardInputEvent = new SearchInputEvent(event, previousTerm)

    if (keyboardInputEvent.isValidSearch(event)) {
      if (!searchUsed) {
        setSearchUsed(true)
      }

      onSearch({
        field: filterField,
        term,
      })
    }
  }

  const handleSearch = debounce(search, 200)
  return (
    <SearchBarStyled>
      <Input placeholder='Search' onChange={handleSearch} inputRef={inputRef} />
    </SearchBarStyled>
  )
}

export default SearchBar
